export var compareDates = function (a, b) { return a.getTime() === b.getTime(); };
export var compareRegexps = function (a, b) {
    return a.source === b.source && a.flags === b.flags;
};
export var compareArrays = function (a, b, equal) {
    var l = a.length;
    if (l !== b.length)
        return false;
    while (l-- && equal(a[l], b[l]))
        ;
    return l === -1;
};
export var compareMaps = function (a, b, equal) {
    if (a.size !== b.size)
        return false;
    var it = a.entries();
    var i;
    while (!(i = it.next()).done) {
        if (!b.has(i.value[0]) || !equal(i.value[1], b.get(i.value[0])))
            return false;
    }
    return true;
};
export var compareSets = function (a, b) {
    if (a.size !== b.size)
        return false;
    var it = a.values();
    var i;
    while (!(i = it.next()).done) {
        if (!b.has(i.value))
            return false;
    }
    return true;
};
export var compareDataViews = function (a, b) {
    var l = a.byteLength;
    if (l !== b.byteLength)
        return false;
    while (l-- && a.getInt8(l) === b.getInt8(l))
        ;
    return l === -1;
};
export var compareArrayBuffers = function (a, b) {
    var l = a.length;
    if (l !== b.length)
        return false;
    while (l-- && a[l] === b[l])
        ;
    return l === -1;
};
var hasOwnProperty = Object.prototype.hasOwnProperty;
var oKeys = Object.keys;
export var compareObjects = function (a, b, equal) {
    var i;
    var len = 0;
    for (i in a) {
        if (hasOwnProperty.call(a, i)) {
            len++;
            if (!hasOwnProperty.call(b, i))
                return false;
            if (!equal(a[i], b[i]))
                return false;
        }
    }
    return oKeys(b).length === len;
};
export var compareObjectsReact = function (a, b, equal) {
    var i;
    var len = 0;
    for (i in a) {
        if (hasOwnProperty.call(a, i)) {
            len++;
            if (a.$$typeof && (i === '_owner' || i === '__v' || i === '__o')) {
                // in React and Preact these properties contain circular references
                // .$$typeof is just reasonable marker of element
                continue;
            }
            if (!hasOwnProperty.call(b, i))
                return false;
            if (!equal(a[i], b[i]))
                return false;
        }
    }
    return oKeys(b).length === len;
};
