import { compareArrayBuffers, compareArrays, compareDataViews, compareDates, compareMaps, compareObjects, compareObjectsReact, compareRegexps, compareSets, } from "./comparators.js";
var _a = Object.prototype, valueOf = _a.valueOf, toString = _a.toString;
export var isEqual = function (a, b) {
    if (a === b)
        return true;
    if (a && b && typeof a === 'object' && typeof b === 'object') {
        var ctor = a.constructor;
        if (ctor !== b.constructor)
            return false;
        if (ctor === Array)
            return compareArrays(a, b, isEqual);
        if (ctor === Date)
            return compareDates(a, b);
        if (ctor === RegExp)
            return compareRegexps(a, b);
        if (ctor === Map && a instanceof Map && b instanceof Map)
            return compareMaps(a, b, isEqual);
        if (ctor === Set && a instanceof Set && b instanceof Set)
            return compareSets(a, b);
        if (ctor === DataView)
            return compareDataViews(a, b);
        if (ctor === ArrayBuffer) {
            return compareArrayBuffers(new Uint8Array(a), new Uint8Array(b));
        }
        if (ArrayBuffer.isView(a) && ArrayBuffer.isView(b)) {
            return compareArrayBuffers(a, b);
        }
        if (a.valueOf !== valueOf)
            return a.valueOf() === b.valueOf();
        if (a.toString !== toString)
            return a.toString() === b.toString();
        return compareObjects(a, b, isEqual);
    }
    // eslint-disable-next-line no-self-compare
    return a !== a && b !== b;
};
export var isEqualReact = function (a, b) {
    if (a === b)
        return true;
    if (a && b && typeof a === 'object' && typeof b === 'object') {
        var ctor = a.constructor;
        if (ctor !== b.constructor)
            return false;
        if (ctor === Array)
            return compareArrays(a, b, isEqual);
        if (ctor === Date)
            return compareDates(a, b);
        if (ctor === RegExp)
            return compareRegexps(a, b);
        if (ctor === Map && a instanceof Map && b instanceof Map)
            return compareMaps(a, b, isEqual);
        if (ctor === Set && a instanceof Set && b instanceof Set)
            return compareSets(a, b);
        if (ctor === DataView)
            return compareDataViews(a, b);
        if (ctor === ArrayBuffer) {
            return compareArrayBuffers(new Uint8Array(a), new Uint8Array(b));
        }
        if (ArrayBuffer.isView(a) && ArrayBuffer.isView(b)) {
            return compareArrayBuffers(a, b);
        }
        if (a.valueOf !== valueOf)
            return a.valueOf() === b.valueOf();
        if (a.toString !== toString)
            return a.toString() === b.toString();
        return compareObjectsReact(a, b, isEqual);
    }
    // eslint-disable-next-line no-self-compare
    return a !== a && b !== b;
};
export var isEqualSimple = function (a, b) {
    if (a === b)
        return true;
    if (a && b && typeof a === 'object' && typeof b === 'object') {
        var ctor = a.constructor;
        if (ctor !== b.constructor)
            return false;
        if (ctor === Array)
            return compareArrays(a, b, isEqual);
        if (ctor === Date)
            return compareDates(a, b);
        if (ctor === RegExp)
            return compareRegexps(a, b);
        if (a.valueOf !== valueOf)
            return a.valueOf() === b.valueOf();
        if (a.toString !== toString)
            return a.toString() === b.toString();
        return compareObjects(a, b, isEqual);
    }
    // eslint-disable-next-line no-self-compare
    return a !== a && b !== b;
};
export var isEqualReactSimple = function (a, b) {
    if (a === b)
        return true;
    if (a && b && typeof a === 'object' && typeof b === 'object') {
        var ctor = a.constructor;
        if (ctor !== b.constructor)
            return false;
        if (ctor === Array)
            return compareArrays(a, b, isEqual);
        if (ctor === Date)
            return compareDates(a, b);
        if (ctor === RegExp)
            return compareRegexps(a, b);
        if (a.valueOf !== valueOf)
            return a.valueOf() === b.valueOf();
        if (a.toString !== toString)
            return a.toString() === b.toString();
        return compareObjectsReact(a, b, isEqual);
    }
    // eslint-disable-next-line no-self-compare
    return a !== a && b !== b;
};
